import React from 'react';
import { bool } from 'prop-types';

import { loadable } from 'nordic/lazy';

import useFullFreeshipping from './use-full-freeshipping';

const FullBottomEmpty = loadable(async () => import('./full-shipping-bottom.mobile'));
const FullShipping = loadable(async () => import('./full-shipping.mobile'));

const FullFreeshippingContainer = ({ disabledByCookie }) => {
  const { currentFullFreeshipping } = useFullFreeshipping();

  if (!currentFullFreeshipping) {
    return null;
  }

  if (
    currentFullFreeshipping.progress_bar &&
    currentFullFreeshipping.progress_bar.color === 'neutral' &&
    currentFullFreeshipping.progress_bar.percent === 0
  ) {
    return <FullBottomEmpty disabledByCookie={disabledByCookie} />;
  }

  return <FullShipping location="down" />;
};

FullFreeshippingContainer.propTypes = {
  disabledByCookie: bool.isRequired,
};

export default FullFreeshippingContainer;
